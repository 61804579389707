import { lighten } from 'polished'
import { createHashHistory } from 'history'
import { Trans } from '@lingui/macro'
import styled, { keyframes } from 'styled-components/macro'
import useWindowSize from 'hooks/useWindowSize'
import { ButtonGray } from 'components/Button'

import LoadingText from 'assets/images/loading-text.png'
const IframeWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
`

const Iframe = styled.iframe`
  overflow: hidden;
`

const cssAnimation = keyframes`
  to { visibility: visible; } 
`
const FilterCard = styled.div`
  position: absolute;
  padding: 100px 74px 90px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.39);
  border: 2px solid ${({ theme }) => lighten(0.5, theme.bg0)};
  filter: blur(0.2px);

  animation: ${cssAnimation} 0s 10s forwards;

  visibility: hidden;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 90%;
    padding: 55px 45px;
  `};
`

const FilterImg = styled.img`
  width: 100%;
`

const FilterButton = styled(ButtonGray)`
  margin-top: 35px;
  margin: 35px auto 0 auto;
`

export default function Preview() {
  const { width, height } = useWindowSize()
  return (
    <IframeWrapper>
      <Iframe src="https://hyperglu.com/synesthesia_no_strokes" width={width} height={height} frameBorder={0} />
      <FilterCard>
        <FilterImg src={LoadingText} alt="SYNESTHESIA" />
        <FilterButton width={'auto'} padding="10px 30px" onClick={() => createHashHistory().push('/')}>
          <Trans>Go Home</Trans>
        </FilterButton>
      </FilterCard>
    </IframeWrapper>
  )
}
