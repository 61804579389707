import { formatEther } from '@ethersproject/units'
import { useActiveWeb3React } from 'hooks/web3'
import { useMemo, useState } from 'react'
import { isAddress } from '../../utils'

export function useETHBalances(uncheckedAddresses?: string | undefined) {
  const { library } = useActiveWeb3React()
  const address = useMemo(() => (isAddress(uncheckedAddresses) ? uncheckedAddresses : ''), [uncheckedAddresses])
  const [balance, setBalance] = useState<string | undefined>()
  useMemo(() => {
    if (!!address && !!library) {
      const stale = false
      library
        .getBalance(address)
        .then((balance: any) => {
          if (!stale) {
            setBalance(formatEther(balance))
          }
        })
        .catch(() => {
          if (!stale) {
            setBalance(undefined)
          }
        })
    }
  }, [address, library])
  return balance
}

// const TOKEN_BALANCE_GAS_OVERRIDE: { [chainId: number]: number } = {
//   [SupportedChainId.MAINNET]: 250_000,
//   [SupportedChainId.RINKEBY]: 250_000,
// }

// /**
//  * Returns a map of token addresses to their eventually consistent token balances for a single account.
//  */
// export function useTokenBalancesWithLoadingIndicator(
//   address?: string,
//   tokens?: (Token | undefined)[]
// ): [{ [tokenAddress: string]: CurrencyAmount<Token> | undefined }, boolean] {
//   const validatedTokens: Token[] = useMemo(
//     () => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false) ?? [],
//     [tokens]
//   )

//   const { chainId } = useActiveWeb3React()

//   const validatedTokenAddresses = useMemo(() => validatedTokens.map((vt) => vt.address), [validatedTokens])
//   const ERC20Interface = new Interface(ERC20ABI) as Erc20Interface
//   const balances = useMultipleContractSingleData(validatedTokenAddresses, ERC20Interface, 'balanceOf', [address], {
//     gasRequired: (chainId && TOKEN_BALANCE_GAS_OVERRIDE[chainId]) ?? 100_000,
//   })

//   const anyLoading: boolean = useMemo(() => balances.some((callState) => callState.loading), [balances])

//   return [
//     useMemo(
//       () =>
//         address && validatedTokens.length > 0
//           ? validatedTokens.reduce<{ [tokenAddress: string]: CurrencyAmount<Token> | undefined }>((memo, token, i) => {
//               const value = balances?.[i]?.result?.[0]
//               const amount = value ? JSBI.BigInt(value.toString()) : undefined
//               if (amount) {
//                 memo[token.address] = CurrencyAmount.fromRawAmount(token, amount)
//               }
//               return memo
//             }, {})
//           : {},
//       [address, validatedTokens, balances]
//     ),
//     anyLoading,
//   ]
// }

// export function useTokenBalances(
//   address?: string,
//   tokens?: (Token | undefined)[]
// ): { [tokenAddress: string]: CurrencyAmount<Token> | undefined } {
//   return ''
// }

// get the balance for a single token/account combo
// export function useTokenBalances(account?: string | null, token?: string) {
//   const [tokenBalance, setTokenBalance] = useState('')
//   const multicallContract = useMulticallContract()
//   useMemo(
//     () => {
//       setTokenBalance('')
//     },
//     multicallContract
//       ?.getTokenBalanceOf(token, account)
//       .then((result: BigNumber) => {
//         setTokenBalance(formatEther(result))
//       })
//       .catch(() => {
//         setTokenBalance('0')
//       }),
//     []
//     multicallContract
//   )
//   return tokenBalance

// multicallContract?.get
// if (!token) return undefined
// return tokenBalances[token.address]
// }

// export function useCurrencyBalances(
//   account?: string,
//   currencies?: (Currency | undefined)[]
// ): (CurrencyAmount<Currency> | undefined)[] {
//   const tokens = useMemo(
//     () => currencies?.filter((currency): currency is Token => currency?.isToken ?? false) ?? [],
//     [currencies]
//   )

//   const tokenBalances = useTokenBalances(account, tokens)
//   const containsETH: boolean = useMemo(() => currencies?.some((currency) => currency?.isNative) ?? false, [currencies])
//   const ethBalance = useETHBalances(containsETH ? [account] : [])

//   return useMemo(
//     () =>
//       currencies?.map((currency) => {
//         if (!account || !currency) return undefined
//         if (currency.isToken) return tokenBalances[currency.address]
//         if (currency.isNative) return ethBalance[account]
//         return undefined
//       }) ?? [],
//     [account, currencies, ethBalance, tokenBalances]
//   )
// }

// export function useCurrencyBalance(account?: string, currency?: Currency): CurrencyAmount<Currency> | undefined {
//   return useCurrencyBalances(account, [currency])[0]
// }
