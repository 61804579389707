import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

/**
 * The Synesthesia address
 */

export const SYNESTHESIA_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x475127d07DF15c38B58a2EBc8fd8e7029FbD4ABB', [SupportedChainId.MAINNET]),
  [SupportedChainId.RINKEBY]: '0xC4a5f954c8970E3123Af4349d4690feAaE8344Eb',
}
