import { useCallback, useContext, useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { TYPE } from 'theme'
import { Inbox } from 'react-feather'
// import InfiniteScroll from 'react-infinite-scroll-component'
import { useSynesthesia } from 'hooks/useContract'
import styled, { ThemeContext } from 'styled-components/macro'

import { useWalletModalToggle } from 'state/application/hooks'

import Column, { AutoColumn } from 'components/Column'
import { ButtonPrimary } from 'components/Button'

import { LoadingRows } from '../styled'

const NFTWrapper = styled(Column)`
  width: 100%;
  margin: 90px 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 30px 0;
  `}
`
const NFTSWrapper = styled(AutoColumn)`
  padding: 0 250px;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 60px;
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 40px;
  `}
`
const NftsOption = styled.div`
  box-shadow: 0px 10px 11px 5px ${({ theme }) => theme.bg2};
  border-radius: 6px;
  overflow: hidden;
`
const NftsOptionImage = styled.img`
  height: calc(100% - 33px);
  vertical-align: top;
`
const MainContentWrapper = styled.main`
  padding: 8px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`
const NoLiquidity = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
`
export default function Nfts() {
  const { account } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const theme = useContext(ThemeContext)
  const SynesConstants = useSynesthesia()
  const showConnectAWallet = Boolean(!account)
  // const [balanceOf, setBalanceOf] = useState([])
  // const isFetching = useRef(false)
  // const [hasMore, setHasMore] = useState(true)
  const [{ nfts, nftsLoading }, setNfts] = useState<{
    nfts: Array<{ image: string; name: string }>
    nftsLoading: boolean
  }>({
    nfts: [],
    nftsLoading: false,
  })

  // async function getListData(isRefresh?: boolean) {
  //   if (isFetching.current) {
  //     return
  //   }
  //   if (!isRefresh && !hasMore) {
  //     return
  //   }
  //   isFetching.current = true

  //   setTimeout(() => {
  //     setNfts({
  //       nfts: nfts.concat(nftItem),
  //       nftsLoading: false,
  //     })
  //   }, 2000)
  //   if (nfts.length >= 100) {
  //     setHasMore(false)
  //   } else {
  //     setHasMore(true)
  //   }
  //   isFetching.current = false
  // }

  // const getBalanceOf = useCallback(async () => {
  //   return setBalanceOf(Array.from({ length: Number(await SynesConstants?.balanceOf(account)) }))
  // }, [SynesConstants, account])

  const getNftList = useCallback(async () => {
    const images: Array<any> = []
    for (let index = 0; index < Number(await SynesConstants?.balanceOf(account)); index++) {
      await SynesConstants?.tokenOfOwnerByIndex(account, index).then(async (tokenId: any) => {
        console.log(
          JSON.parse(atob(String(await SynesConstants?.tokenURI(tokenId)).replace('data:application/json;base64,', '')))
        )

        const json = JSON.parse(
          atob(String(await SynesConstants?.tokenURI(tokenId)).replace('data:application/json;base64,', ''))
        )
        images.push(json)
      })
    }
    setNfts({
      nfts: images,
      nftsLoading: false,
    })
  }, [SynesConstants, account])

  useEffect(() => {
    getNftList()
    setNfts({
      nfts,
      nftsLoading: true,
    })
    return () => {
      setNfts({
        nfts: [],
        nftsLoading: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <NFTWrapper>
      <MainContentWrapper>
        {nftsLoading ? (
          <LoadingRows>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </LoadingRows>
        ) : nfts && nfts.length > 0 ? (
          // <InfiniteScroll
          //   dataLength={nfts.length}
          //   next={getListData}
          //   hasMore={hasMore}
          //   loader={
          //     <Row justify="center" padding={'10px 0'}>
          //       <Dots>
          //         <Trans>Loading</Trans>
          //       </Dots>
          //     </Row>
          //   }
          //   // height={600}
          //   endMessage={
          //     <Row justify="center" padding={'10px 0'}>
          //       <Trans>Yay! You have seen it all</Trans>
          //     </Row>
          //   }
          // >
          <NFTSWrapper>
            {nfts.map((item, index) => (
              <NftsOption key={index}>
                <NftsOptionImage src={item.image} key={index} width={'100%'} height={'100%'} />
                <AutoColumn
                  style={{
                    padding: '5px 8px',
                  }}
                >
                  <TYPE.black fontSize={14} fontWeight={500} color={theme.text2}>
                    {item.name}
                  </TYPE.black>
                </AutoColumn>
              </NftsOption>
            ))}
          </NFTSWrapper>
        ) : (
          // </InfiniteScroll>
          <NoLiquidity>
            <TYPE.body color={theme.text3} textAlign="center">
              <Inbox size={48} strokeWidth={1} style={{ marginBottom: '.5rem' }} />
              <div>
                <Trans>All NFTS will appear here.</Trans>
              </div>
            </TYPE.body>
            {showConnectAWallet && (
              <ButtonPrimary style={{ marginTop: '2em', padding: '8px 16px' }} onClick={toggleWalletModal}>
                <Trans>Connect a wallet</Trans>
              </ButtonPrimary>
            )}
          </NoLiquidity>
        )}
      </MainContentWrapper>
    </NFTWrapper>
  )
}
