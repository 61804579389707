import styled from 'styled-components/macro'

const ProcessWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Building = styled.p``
export default function Process() {
  return (
    <ProcessWrapper>
      <Building>Building</Building>
    </ProcessWrapper>
  )
}
