import { Contract } from '@ethersproject/contracts'
import { getContract } from 'utils'
import { useActiveWeb3React } from './web3'

import SYNESTHESIA_ABI from 'constants/abis/synesthesia.abi.json'

import { SYNESTHESIA_ADDRESSES } from 'constants/addresses'

import { useMemo } from 'react'
// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account]) as T
}

export function useSynesthesia(): Contract | null {
  return useContract(SYNESTHESIA_ADDRESSES, SYNESTHESIA_ABI, true)
}
