import { useState, useMemo, useContext, useCallback, useEffect } from 'react'
import { t, Trans } from '@lingui/macro'

import { useActiveWeb3React } from 'hooks/web3'
import { useWalletModalToggle } from 'state/application/hooks'
import { useDarkModeManager } from 'state/user/hooks'
import { useETHBalances } from 'state/wallet/hooks'

import useInterval from 'hooks/useInterval'
import { useSynesthesia } from 'hooks/useContract'
import { BigNumber } from '@ethersproject/bignumber'
import { parseEther } from '@ethersproject/units'

// import ReactPlayer from 'react-player/lazy'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'
import { lighten } from 'polished'
import { ArrowRight, MinusCircle, PlusCircle, Instagram } from 'react-feather'

import { StyledInternalLink } from 'theme'
import styled, { ThemeContext } from 'styled-components/macro'
import Column, { AutoColumn, ColumnCenter } from 'components/Column'
import Row, { AutoRow, RowBetween } from 'components/Row'
import { ButtonEmpty, ButtonGray, ButtonPrimary } from 'components/Button'
import { DarkCard } from 'components/Card'
import NumericalInput from 'components/NumericalInput'
import TransactionSubmissionModal from 'components/TransactionSubmissionModal'

import { defaultChainId } from 'constants/chains'
import { BucketSwiper } from './bucketSwiper'

import synesBanner from 'assets/images/synes-banner.png'
import synesBannerMobile from 'assets/images/banner-mobile.png'

import IntroductionBg from 'assets/images/introduction-bg.png'
import ProcessBg from 'assets/images/process-bg.png'
import SynestHesia from 'assets/images/synest-hesia.png'
import IconO from 'assets/images/icon-1.png'
import IconT from 'assets/images/icon-2.png'
import IconW from 'assets/images/icon-3.png'
import BlueLine from 'assets/images/blue-line.png'
import PinkLine from 'assets/images/pink-line.png'
import Synesthesia from 'assets/images/synesthesia.png'

import CertificationPink from 'assets/svg/certification_pink.svg'
import CertificationWhite from 'assets/svg/certification_white.svg'
import TwitterPink from 'assets/svg/twitter_pink.svg'
import TwitterWhite from 'assets/svg/twitter_white.svg'
import Avatar from 'assets/svg/avatar.svg'
import { switchToNetwork } from 'utils/switchToNetwork'

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

const HomeWrapper = styled(ColumnCenter)`
  width: 100%;
`
const BannerWrapper = styled(HomeWrapper)`
  height: 600px;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 700px;
  `}
`
const BannerMintWrapper = styled(AutoColumn)`
  min-width: 380px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 20px;
  `}
`
const MintButton = styled(ButtonGray)`
  box-shadow: 0px 2px 0px ${({ theme }) => theme.blue2};
`
const MintEdit = styled.div`
  padding-left: 0.9375rem;
  display: flex;
  align-items: center;
  & > svg {
    :first-child {
      margin-right: 0.4375rem;
    }
    :last-child {
      margin-left: 0.4375rem;
    }
  }
`
const MintInput = styled(NumericalInput)`
  flex: 1 1 auto;
  width: 0;
  max-width: 60px;

  font-weight: 400;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 3px;

  padding: 7px 5px 6px;
`
const TitleWrapper = styled(Text)`
  font-size: 4rem;
  font-family: Exo-Bold-Italic;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 39px;
    text-align: center;
  `}
`
const Introduction = styled.div`
  position: relative;
  background: url(${IntroductionBg}) center center / cover;
  padding: 100px 250px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 60px;
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 40px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px;
  `}
`
const HypergluWrapper = styled(RowBetween)`
  position: relative;
  margin-top: 3.4375rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column-reverse;
    margin-top: 1.875rem;
  `}
`
const IntroductionDesc = styled(AutoColumn)`
  padding: 2.3125rem 1.75rem 2.8125rem;
  background: ${({ theme }) => theme.advancedBG};
  box-shadow: ${({ theme }) => `0px 2px 22px  ${lighten(0.96, theme.text1)}`};
  width: 45%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%
  `};
`
const HypergVoide = styled.div`
  width: 45%;
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%
  `};
`
const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`
const DescLine = styled.div`
  position: absolute;
  width: 41px;
  height: 230px;
  background: linear-gradient(180deg, #e9f5f3 0%, rgba(255, 255, 255, 0) 100%);
  right: -130px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    right: 0;
  `};
`
const IntroductionHesia = styled.img`
  position: absolute;
  width: 55%;
  overflow: hidden;
  left: -3.75rem;
  bottom: 0;
`
const DescButton = styled(ButtonGray)`
  padding: 0.3125rem 0.4375rem;
  width: auto;
`

const ProcessWrapper = styled(Column)`
  position: relative;

  padding: 100px 250px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 60px;
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 40px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 60px 20px;
  `}
`
const ProcessBgWrapper = styled.img`
  width: 100%;
  max-height: 412px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -2;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 300px;
  `}
`
const ProcessListWrapper = styled(AutoColumn)`
  margin-top: 5.9375rem;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    margin-top: 3.125rem;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  `};
`
const ProcessListItem = styled(Column)`
  position: relative;
  padding: 40px 32px 20px;
  background: ${({ theme }) => theme.bg0};
`
const ProcessListItemShadow = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  position: absolute;
  top: 6px;
  bottom: -6px;
  left: 6px;
  right: -6px;
  z-index: -1;
`
const ProcessItemTitle = styled(Row)`
  & > div {
    flex: 1;
  }
`
const BlueLines = styled.img`
  position: absolute;
  width: 2.1875rem;
  max-height: 230px;
  top: 37.5%;
  left: unset;
  right: 7%;
`
const PinkLines = styled(BlueLines)`
  top: 57.5%;
  left: 7%;
  right: unset;
`
const SynesthesiaCard = styled(DarkCard)`
  padding: 50px 78px 50px 90px;
  margin-top: 75px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 50px;
    padding: 20px;
  `}
`
const SynesthesiaIcon = styled.img`
  width: 225px;
  height: 212px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 145px;
    height: 140px;
  `}
`
const SynesthesiaItem = styled(Row)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `}
`
const SynesthesiaDesc = styled(AutoColumn)`
  padding-left: 6.875rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-left: unset;
    padding-top: 47px;
  `}
`
const SynesthesiaText = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => lighten(0.2, theme.text1)};
`

const BucketWrapper = styled(Column)`
  width: 100%;
  padding: 70px 250px;
  background: ${({ theme }) => theme.bg7};
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 60px;
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 40px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 20px;
  `}
`
const BucketListWrapper = styled.div`
  padding: 25px 0px 50px;
`

export default function Home() {
  const { account, chainId, library } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const [darkMode] = useDarkModeManager()
  const userEthBalance = useETHBalances(account ? account : '')

  const theme = useContext(ThemeContext)
  const SynesConstants = useSynesthesia()
  const showConnectAWallet = Boolean(!account)
  const showSwitchAMainnet = Boolean(chainId !== defaultChainId)

  // 0, NOT STARTED YET 1, MINT 2, STOP
  const [{ saleState, maxSupply }, setConfig] = useState<{ saleState: number; maxSupply: number | undefined }>({
    saleState: -1,
    maxSupply: undefined,
  })
  const [totalMinted, setTotalMinted] = useState<number | undefined>()
  const [mintAmount, setMintAmount] = useState('1')
  const [{ minting, minthash, mintErrorMessage }, setModal] = useState<{
    minting: boolean
    minthash: string | undefined
    mintErrorMessage: string | undefined
  }>({
    minting: false,
    minthash: undefined,
    mintErrorMessage: undefined,
  })

  const processList = useMemo(() => {
    return [
      {
        icon: IconO,
        text: 'Synesthesia will open the first generative art co-creation space for participants to join in the creation of the art. Participants can burn their Color NFT to influence the output artwork.',
        shadow: '#FDC8F1',
      },
      {
        icon: IconT,
        text: 'After the artwork is completed, it will send to the auction automatically. (how the reserve price voting works will be announced)',
        shadow: '#C8D5F5',
      },
      {
        icon: IconW,
        text: 'If the generated NFT artwork goes to auction, 80% of the total sales proceeds will be distributed to the users who participated in creating the art. After the purchase, the buyer will be given the option to destroy the art and recover all the original Colors NFTs burned for its creation.',
        shadow: '#F5CBB6',
      },
    ]
  }, [])

  const handleDismissSubmissionModal = useCallback(() => {
    setModal({
      minting: false,
      minthash: undefined,
      mintErrorMessage: undefined,
    })
  }, [setModal])

  const handleAmountInput = useCallback(
    (value: string) => {
      value = value.replace(/\D+/, '')
      setMintAmount(Number(value) >= 20 ? '20' : value)
    },
    [setMintAmount]
  )
  const handleMinus = useCallback(
    (val: number) => {
      if (val > 1) {
        setMintAmount(String((val -= 1)))
      }
    },
    [setMintAmount]
  )
  const handlePlus = useCallback(
    (val: number) => {
      setMintAmount(val >= 20 ? '20' : String((val += 1)))
    },
    [setMintAmount]
  )

  const getSaleState = useCallback(async () => {
    await SynesConstants?._config()
      .then((result: { saleState: number; maxSupply: number; minterSeed: BigNumber; randomSeed: BigNumber }) => {
        const { saleState, maxSupply } = result
        setConfig({ saleState, maxSupply })
      })
      .catch(() => {
        setConfig({ saleState, maxSupply: 0 })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SynesConstants, setConfig])

  const getTotalMinted = useCallback(async () => {
    await SynesConstants?.totalMinted()
      .then((result: any) => {
        setTotalMinted(Number(result))
      })
      .catch(() => {
        setTotalMinted(undefined)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SynesConstants, setTotalMinted])

  const init = useCallback(() => {
    getSaleState()
    getTotalMinted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useInterval(init, showConnectAWallet ? null : 5000)

  const putMint = useCallback(
    async (e, amount) => {
      e.stopPropagation()
      if (Number(userEthBalance) < 0.055 * Number(amount)) {
        setModal({
          minting: true,
          minthash,
          mintErrorMessage: t`Insufficient ETH balance`,
        })
        return
      }
      setModal({
        minting: true,
        minthash,
        mintErrorMessage,
      })
      await SynesConstants?.sqeezePigmentTube(amount, {
        value: parseEther(String(0.055 * amount)),
      })
        .then(async (result: any) => {
          const { wait } = result
          wait &&
            (await wait()
              .then((res: { transactionHash: string }) => {
                const { transactionHash } = res
                setModal({
                  minting: true,
                  mintErrorMessage,
                  minthash: transactionHash,
                })
              })
              .catch((err: any) => console.log(err)))
        })
        .catch((error: any) => {
          setModal({
            minting: true,
            minthash,
            mintErrorMessage: error.message,
          })
        })
        .finally(() => init())
    },
    [SynesConstants, mintErrorMessage, minthash, userEthBalance, init]
  )

  useEffect(() => {
    if (!account) return
    init()
    return () => {
      setConfig({
        saleState: -1,
        maxSupply: 0,
      })
      setTotalMinted(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])
  return (
    <HomeWrapper>
      <TransactionSubmissionModal
        isOpen={minting}
        hash={minthash}
        onDismiss={handleDismissSubmissionModal}
        errorMessage={mintErrorMessage}
      />
      <BannerWrapper style={{ background: `url(${isMobile ? synesBannerMobile : synesBanner}) center center / cover` }}>
        <BannerMintWrapper gap="15px">
          {showConnectAWallet ? (
            <ButtonPrimary style={{ marginTop: '2em', padding: '8px 16px' }} onClick={toggleWalletModal}>
              <Trans>Connect a wallet</Trans>
            </ButtonPrimary>
          ) : showSwitchAMainnet ? (
            <ButtonPrimary
              style={{ marginTop: '2em', padding: '8px 16px' }}
              onClick={() => {
                if (!library?.provider?.request || !chainId || !library?.provider?.isMetaMask) {
                  return
                }
                switchToNetwork({ library, chainId: defaultChainId })
              }}
            >
              <Trans>Switch a Mainnet</Trans>
            </ButtonPrimary>
          ) : (
            <>
              {totalMinted === maxSupply ? (
                <>
                  <MintButton
                    $borderRadius="7px"
                    onClick={() => window.open('https://opensea.io/collection/synesthesia-space')}
                  >
                    <Trans>Purchase at OpenSea</Trans>
                  </MintButton>
                  <AutoColumn gap="sm">
                    <Text fontSize={12} color={lighten(0.3, theme.text4)} textAlign={'center'}>
                      <Trans>
                        <StyledInternalLink to={'/app/home'}>Or start creating (Coming Soon)</StyledInternalLink>
                      </Trans>
                    </Text>
                  </AutoColumn>
                </>
              ) : (
                <>
                  <MintButton
                    $borderRadius="7px"
                    disabled={saleState !== 1}
                    onClick={(e) => putMint(e, parseInt(mintAmount))}
                  >
                    {saleState === 0 ? (
                      <>
                        <Text fontSize={26} fontWeight={'bold'}>
                          <Trans>NOT STARTED YET</Trans>
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text fontSize={26} fontWeight={'bold'}>
                          <Trans>MINT</Trans>
                        </Text>
                        <MintEdit onClick={(e) => e.stopPropagation()}>
                          <MinusCircle size={16} onClick={() => handleMinus(parseInt(mintAmount))} />
                          <MintInput
                            fontSize="12px"
                            value={mintAmount}
                            onUserInput={(val) => handleAmountInput(val)}
                            placeholder="0"
                          />
                          <PlusCircle size={16} onClick={() => handlePlus(parseInt(mintAmount))} />
                        </MintEdit>
                      </>
                    )}
                  </MintButton>
                  <AutoColumn gap="sm">
                    <Text fontSize={12} color={lighten(0.3, theme.text4)} textAlign={'center'}>
                      {totalMinted} / {maxSupply} Minted {0.055 * Number(mintAmount)}Ξ
                    </Text>
                    <Text fontSize={12} color={lighten(0.3, theme.text4)} textAlign={'center'}>
                      <Trans>Maximum 20 NFT mints per transaction.</Trans>
                    </Text>
                    <Text fontSize={12} color={lighten(0.3, theme.text4)} textAlign={'center'}>
                      <Trans>Reveal will happen completely on chain 24h after fully minted.</Trans>
                    </Text>
                    <Text fontSize={12} color={lighten(0.3, theme.text4)} textAlign={'center'}>
                      <Trans>Minting gas consumption has been significantly reduced with optimal contract code. </Trans>
                    </Text>
                    <Text fontSize={12} color={lighten(0.3, theme.text4)} textAlign={'center'}>
                      <Trans>A single NFT costs 0.006E gas to mint using 80 gwei gas.</Trans>
                    </Text>
                  </AutoColumn>
                </>
              )}
            </>
          )}
        </BannerMintWrapper>
      </BannerWrapper>
      <Introduction>
        <IntroductionHesia src={SynestHesia} alt={'SynestHesia'} />
        <AutoColumn gap="md">
          <TitleWrapper>
            <Trans>INTRODUCTION</Trans>
          </TitleWrapper>
          <Text fontSize={[14, 16]}>
            <Trans>
              Synesthesia, officially launching as the first collaborative NFT art project in partnership with
              well-known generative artist Hyperglu.
            </Trans>
          </Text>
          <Text>
            <Trans>
              We start with 1600 unique color palettes and create multiple Color NFTs for each palette for a total of
              16000. Synesthesia will enable users to use their Colors NFTs to participate in the creation of new
              generative artworks.
            </Trans>
          </Text>
          <Row height={1.2} backgroundColor={theme.bg3} />
          <Text>
            <Trans>
              Similar to the <StyledInternalLink to={'/preview'}>preview</StyledInternalLink>, it is an endlessly
              changing generative NFT designed by Hyperglu. With our Color NFT, you will continuously participate in the
              artistic creation of Synesthesia and in our future activities.
            </Trans>
          </Text>
          <Text>
            <Trans>
              What will happen with this combination of colors and algorithm? Seize the possiblity and join us in
              Synesthesia. We believe this will be an unprecedented experience in novel generative art technology.
            </Trans>
          </Text>

          <HypergluWrapper>
            <DescLine />
            <IntroductionDesc>
              <IconWrapper size={90}>
                <img src={Avatar} alt={'Avatar'} />
              </IconWrapper>
              <Text fontSize={20} padding={'12px 0 15px'} textAlign={'center'} fontWeight={'bold'}>
                <Trans>Hyper Glu</Trans>
              </Text>
              <AutoRow gap="10px" justify="center">
                <DescButton onClick={() => window.open('https://twitter.com/hyper_glu')}>
                  <Row>
                    <IconWrapper size={12}>
                      <img src={darkMode ? CertificationWhite : CertificationPink} alt={'Certification'} />
                    </IconWrapper>
                    <Text fontSize={12} margin={'0 3px'}>
                      <Trans>@hyper_glu</Trans>
                    </Text>
                    <IconWrapper size={12}>
                      <img src={darkMode ? TwitterWhite : TwitterPink} alt={'Twitter'} />
                    </IconWrapper>
                  </Row>
                </DescButton>
                <DescButton onClick={() => window.open('https://www.instagram.com/hyperglu/')}>
                  <Row>
                    <IconWrapper size={12}>
                      <img src={darkMode ? CertificationWhite : CertificationPink} alt={'Certification'} />
                    </IconWrapper>
                    <Text fontSize={12} margin={'0 3px'}>
                      <Trans>hyperglu</Trans>
                    </Text>
                    <Instagram size={12} />
                  </Row>
                </DescButton>
              </AutoRow>
              <Text fontSize={12} textAlign={'center'} lineHeight={'24px'} marginTop={30}>
                <Trans>
                  Hyper Glu is a pioneering artist in generative art. He studied mathematics and technology but spun
                  around with art and design. Six - seven years ago, fueled by passion and curiosity, he started to
                  automate and generate visual outputs using different programming languages, mathematical patterns, and
                  sometimes even physics. He thinks his works sit at the edge of traditional techniques, to cross the
                  line of “what is humanly possible to draw” and scale the complexity and variations to a level that
                  would be impossible to achieve by hand on a physical canvas.
                </Trans>
              </Text>
              <ButtonEmpty onClick={() => window.open('https://foundation.app/@hyperglu')}>
                <Text fontSize={[16, 12]}>
                  <Trans>Learn more</Trans>
                </Text>
                <ArrowRight size={14} style={{ paddingLeft: 3, marginTop: -1 }} />
              </ButtonEmpty>
            </IntroductionDesc>
            <HypergVoide>
              <Iframe src="https://hyperglu.com/synesthesia_no_strokes" frameBorder={0} />
              {/* <ReactPlayer loop playing width="100%" height="100%" url="/video/defrag.mov" /> */}
            </HypergVoide>
          </HypergluWrapper>
        </AutoColumn>
      </Introduction>
      <ProcessWrapper>
        <ProcessBgWrapper src={ProcessBg} alt={'ProcessBg'} />
        <BlueLines src={BlueLine} alt={'BlueLine'} />
        <PinkLines src={PinkLine} alt={'PinkLine'} />
        <TitleWrapper>
          <Trans>PROCESS</Trans>
        </TitleWrapper>
        <ProcessListWrapper>
          {processList.map((item, index) => (
            <ProcessListItem key={`item-${index}`}>
              <ProcessListItemShadow color={item.shadow} />
              <ProcessItemTitle>
                <Text fontFamily={'Exo-Bold'} fontSize={54}>
                  0{index + 1}
                </Text>
                <IconWrapper size={120}>
                  <img src={item.icon} alt={item.text} />
                </IconWrapper>
              </ProcessItemTitle>
              <Text fontSize={14} marginTop={30}>
                {item.text}
              </Text>
            </ProcessListItem>
          ))}
        </ProcessListWrapper>

        <SynesthesiaCard $borderRadius="0px">
          <SynesthesiaItem>
            <SynesthesiaIcon src={Synesthesia} alt="Synesthesia" />
            <SynesthesiaDesc gap="23px">
              <Text fontSize={18} fontFamily={'Exo-Bold'}>
                <Trans>
                  Synesthesia will fully support the OpenDAO; 10% of sales proceeds will be used to purchase $SOS, and
                  the other 10% will go directly to OpenDAO&apos;s vault.
                </Trans>
              </Text>
              <AutoColumn gap="10px">
                <SynesthesiaText>
                  <Trans>We will work with reputable auction houses that accept $ETH and $SOS.</Trans>
                </SynesthesiaText>
                <SynesthesiaText>
                  <Trans>
                    80% of the auction proceeds will be returned to the participants who destroyed the NFT, and the
                    remaining 20% will be used to promote the project.
                  </Trans>
                </SynesthesiaText>
              </AutoColumn>
            </SynesthesiaDesc>
          </SynesthesiaItem>
        </SynesthesiaCard>
      </ProcessWrapper>
      <BucketWrapper>
        <Text fontSize={18} fontWeight={'bold'}>
          <Trans>Bucket List NL</Trans>
        </Text>
        <BucketListWrapper>
          <BucketSwiper />
        </BucketListWrapper>
      </BucketWrapper>
    </HomeWrapper>
  )
}
