import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'

const FooterFrame = styled.footer`
  ${({ theme }) => theme.flexRowNoWrap};
  width: 100%;
  background: ${({ theme }) => theme.bg0};
  align-items: center;
  justify-content: center;
  padding: 20px 0px 19px;
`
const Copyright = styled.span`
  color: ${({ theme }) => theme.text1};
`
export default function Footer() {
  return (
    <FooterFrame>
      <Copyright>
        <Trans>© {new Date().getFullYear()} Synesthesia</Trans>
      </Copyright>
    </FooterFrame>
  )
}
