import styled from 'styled-components/macro'
import { nftItem } from '../styled'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// import Swiper core and required modules
import SwiperCore, { EffectCoverflow, Navigation } from 'swiper'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
// install Swiper modules
SwiperCore.use([EffectCoverflow, Navigation])

const Swipers = styled(Swiper)`
  .swiper-button-prev {
    color: ${({ theme }) => theme.text1};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        left: 1px
    `};
  }
  .swiper-button-next {
    color: ${({ theme }) => theme.text1};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        right: 1px
    `};
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 180px;
        height: 180px;
    `};
  }
`
const NftImg = styled.img`
  border: 6px solid ${({ theme }) => theme.text1};
  padding: 6px;
`

export function BucketSwiper() {
  return (
    <Swipers
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={false}
      slidesPerView={'auto'}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      navigation
    >
      {nftItem.map((el, i) => {
        return (
          <SwiperSlide key={i}>
            <NftImg src={el} width={'100%'} height={'100%'} />
          </SwiperSlide>
        )
      })}
    </Swipers>
  )
}
