import styled, { keyframes } from 'styled-components/macro'
import { StyledInternalLink } from 'theme'
import EGGNOG from 'assets/nfts/EGGNOG.svg'
import ALFALFA from 'assets/nfts/ALFALFA.svg'
import ALESAN from 'assets/nfts/ALESAN.svg'
import QUIET from 'assets/nfts/QUIET.svg'
import BURNT from 'assets/nfts/BURNT.svg'
import PEACH from 'assets/nfts/PEACH.svg'
import PINK from 'assets/nfts/PINK.svg'
import PEPPERCORN from 'assets/nfts/PEPPERCORN.svg'
import WINSOME from 'assets/nfts/WINSOME.svg'
import ULTRAMARINE from 'assets/nfts/ULTRAMARINE.svg'
import INKLING from 'assets/nfts/INKLING.svg'
import FLORIDA from 'assets/nfts/FLORIDA.svg'
import ZEPHYR from 'assets/nfts/ZEPHYR.svg'
import SUNNY from 'assets/nfts/SUNNY.svg'
import ARTISANSGOLD from 'assets/nfts/ARTISANSGOLD.svg'
import BUTTERCUP from 'assets/nfts/BUTTERCUP.svg'
export const nftItem = [
  EGGNOG,
  ALFALFA,
  ALESAN,
  QUIET,
  BURNT,
  PEACH,
  PINK,
  PEPPERCORN,
  WINSOME,
  ULTRAMARINE,
  INKLING,
  FLORIDA,
  ZEPHYR,
  SUNNY,
  ARTISANSGOLD,
  BUTTERCUP,
]

export const ArrowWrapper = styled(StyledInternalLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 24px;
  color: ${({ theme }) => theme.text1};

  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
  }
  :hover {
    text-decoration: none;
  }
`

const loadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const LoadingRows = styled.div`
  margin: auto;
  display: grid;
  min-width: 75%;
  /* grid-column-gap: 0.5em;
  grid-row-gap: 0.8em; */
  grid-gap: 30px;
  grid-template-columns: repeat(4, 1fr);
  & > div {
    animation: ${loadingAnimation} 1.5s infinite;
    animation-fill-mode: both;
    background: linear-gradient(
      to left,
      ${({ theme }) => theme.bg1} 25%,
      ${({ theme }) => theme.bg3} 50%,
      ${({ theme }) => theme.bg1} 75%
    );
    background-size: 400%;
    border-radius: 12px;
    height: 12em;
    will-change: background-position;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  `}
`

export const DetailLoadingRows = styled.div`
  display: grid;
  min-width: 75%;
  max-width: 960px;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.8em;
  grid-template-columns: repeat(2, 1fr);
  & > div {
    animation: ${loadingAnimation} 1.5s infinite;
    animation-fill-mode: both;
    background: linear-gradient(
      to left,
      ${({ theme }) => theme.bg1} 25%,
      ${({ theme }) => theme.bg2} 50%,
      ${({ theme }) => theme.bg1} 75%
    );
    background-size: 400%;
    border-radius: 12px;
    height: 6em;
    will-change: background-position;
  }

  & > div:nth-child(4n + 1) {
    grid-column: 1 / 3;
  }
  & > div:nth-child(4n) {
    grid-column: 3 / 4;
    margin-bottom: 2em;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 100%;
    grid-template-columns: repeat(1, 1fr);
  `}
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
